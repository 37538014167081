import axios from 'axios';
import { AccountRequest } from '../module/portfolio/context';

const instance = axios.create({
    baseURL: "https://api." + window.location.hostname.replace(".local.", "."),
    timeout: 30000,
    withCredentials: true,
});

const publicInstance = axios.create({
    baseURL: "https://api.halogen.my",
    timeout: 30000,
    withCredentials: true,
});

export const getSession = async () => await instance.get("/session")

export const sendAuthEmail = async (email: string) => await instance.post("/auth/email", {
    email,
})

export const deleteAuthEmail = async () => await instance.delete(`/auth/email`)

export const verifyAuthEmail = async (id: string) => await instance.get(`/auth/email?id=${id}`)

export const setPersonaTitle = async (title: string) => await instance.post(`/command`, {
    name: "wallet_set_persona_title",
    payloadJSON: JSON.stringify({
        title
    })
})

export const getPersona = async () => await instance.post(`/query`, {
    name: "wallet_get_persona",
    payloadJSON: JSON.stringify({})
})

export const listPersonaClients = async () => await instance.post(`/query`, {
    name: "wallet_list_persona_clients",
    payloadJSON: JSON.stringify({})
})

export interface GetClientAccountValueOutput {
    accountId: string
    accountName: string
    quoteAsset: string
    netInflow: number
    totalInflow: number
    totalOutflow: number
    portfolioValue: number
    pnlAmount: number
    pnlPercentage: number
    since: string
}

export const getClientAccountValue = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_get_client_account_portfolio",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

export interface AccountBreakdown {
    fundId: string
    fundClassSequence: number
    fundName: string
    shortName: string
    fundCode: string
    canDistribute: boolean
    fundClassLabel: string
    units: number
    quoteAsset: string
    netInflow: number
    totalInflow: number
    totalRebate: number
    totalOutflow: number
    value: number
    valuationDate: string
    distributedAmount: number
    pnlAmount: number
    pnlPercentage: number
    allocationPercentage: number
}

export interface GetClientAccountValueBreakdownOutput {
    breakdown: AccountBreakdown[]
}

export const getClientAccountValueBreakdown = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_get_client_account_portfolio_breakdown",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

export const getClientAccountSwitchInValue = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_get_client_account_switchin_value",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

export const getClientAccountRequests = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_get_client_account_requests",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

export const getFund = async (fundId: string) => await instance.post("/query", {
    name: "wallet_get_fund",
    payloadJSON: JSON.stringify({
        fundId,
    })
})

export const listFunds = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_list_funds",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

export const listFundHoldings = async (clientId: string, accountId: string, fundId: string) => await instance.post("/query", {
    name: "wallet_list_client_holding_for_fund",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
        fundId,
    })
})

export const getClient = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_get_client",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

export interface BankAccount {
    bankName: string
    accountName: string
    accountNumber: string
    bic: string
    status: "verified" | "unverified" | "deleted"
}

export interface ListClientBankAccountsOutput {
    bankAccounts: BankAccount[]
}

export const listClientBankAccounts = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_list_client_bank_accounts",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

export interface CreateClientBankAccountInput {
    clientId: string
    accountId: string
    bic: string
    accountName: string
    accountNumber: string
}

export const createClientBankAccount = async (input: CreateClientBankAccountInput) => await instance.post("/command", {
    name: "wallet_create_client_bank_account",
    payloadJSON: JSON.stringify(input)
})

export const contactSupport = async (clientId: string, accountId: string, message: string) => await instance.post("/command", {
    name: "wallet_contact_support",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
        message,
    })
})

export const createSuitabilityAssessment = async (input: CreateSuitabilityAssessmentInput) => await instance.post("/command", {
    name: "wallet_create_suitability_assessment",
    payloadJSON: JSON.stringify(input)
})

interface UpdateInvestorCategory {
    clientId: string
    accountId: string
    investorCategory: "accreditedInvestor" | "highNetworthInvestor" | "sophisticatedInvestor250k" | "retailInvestor"
    accreditedLicenseNumber?: string
}

export const updateInvestorCategory = async (input: UpdateInvestorCategory) => await instance.post("/command", {
    name: "wallet_update_investor_category",
    payloadJSON: JSON.stringify(input)
})

interface InvestInput {
    clientId: string
    accountId: string
    fundId: string
    fundClassSequence: number
    amount: number
    source: "wallet"
    type: "purchase"
    voucherCode: string
}

export const invest = async (input: InvestInput) => await instance.post("/command", {
    name: "wallet_invest",
    payloadJSON: JSON.stringify(input)
})

interface RedeemInput {
    clientId: string
    accountId: string
    fundId: string
    toBankAccountNumber: string
    fundClassSequence: number
    requestedAmount: number
    source: "wallet"
    type: "redeem"
}

export const redeem = async (input: RedeemInput) => await instance.post("/command", {
    name: "wallet_redeem",
    payloadJSON: JSON.stringify(input)
})

interface SwitchRequestInput {
    clientId: string
    accountId: string
    switchFromFundId: string
    switchFromFundClassSequence: number
    switchToFundId: string
    switchToFundClassSequence: number
    requestedAmount: number
}

export const switchRequest = async (input: SwitchRequestInput) => await instance.post("/command", {
    name: "wallet_switch",
    payloadJSON: JSON.stringify(input)
})

export interface CreateSuitabilityAssessmentInput {
    clientId: string
    accountId: string
    suitabilityAssessment: {
        investmentExperience: string
        investmentObjective: string
        investmentHorizon: string
        currentInvestment: string
        returnExpectations: string
        source: string
    }
}

export interface GetBalanceOutput {
    balance: Map<string, Map<string, {
        units: number,
        value: number,
    }>>
}

export const getBalance = async (clientId: string, accountId: string) => await instance.post("/query", {
    name: "wallet_get_balance",
    payloadJSON: JSON.stringify({
        clientId,
        accountId,
    })
})

interface CancelClientRequestInput {
    clientId: string
    accountId: string
    requestId: string
}

export const cancelClientRequest = async (input: CancelClientRequestInput) => await instance.post("/command", {
    name: "wallet_cancel_request",
    payloadJSON: JSON.stringify({
        clientId: input.clientId,
        accountId: input.accountId,
        requestId: input.requestId,
    })
})

export interface CreateAccountInput {
    email: string
    referrerCode: string
}

export const createAccount = async (input: CreateAccountInput) => await instance.post("/auth/applicant", input)

export const verifyAuthApplicant = async (id: string) => await instance.get(`/auth/applicant?id=${id}`)

export const getOnfidoSession = async () => await instance.post("/command", {
    name: "wallet_get_onfido_session",
    payloadJSON: "{}"
})

export const getApplicantDetails = async () => await instance.post("/query", {
    name: "wallet_get_applicant_details",
    payloadJSON: "{}"
})

interface SetApplicantFieldInput {
    title?: string
    nationality?: string
    onfido?: Object

    employmentType?: string
    otherEmploymentType?: string

    sourceOfWealth?: string
    otherSourceOfWealth?: string
}

export const setApplicantField = async (input: SetApplicantFieldInput) => await instance.post("/command", {
    name: "wallet_set_applicant_field",
    payloadJSON: JSON.stringify(input)
})

interface SetProfileFieldInput {
    clientId: string
    accountId: string

    ethnicity?: string
    otherEthnicity?: string
    domesticRinggitBorrowing?: string
    taxResidency?: string
    countryTax?: string
    taxIdentificationNo?: string
}

export const setProfileField = async (input: SetProfileFieldInput) => await instance.post("/command", {
    name: "wallet_set_profile_field",
    payloadJSON: JSON.stringify(input)
})

interface VerifyMsisdnInput {
    msisdn: {
        value: string
        countryCodeLetters: string
    }
}

export const verifyMsisdn = async (input: VerifyMsisdnInput) => await instance.post("/command", {
    name: "wallet_verify_msisdn",
    payloadJSON: JSON.stringify(input)
})

export const verifyMsisdnCode = async (code: string) => await instance.post("/command", {
    name: "wallet_verify_msisdn_code",
    payloadJSON: JSON.stringify({
        code
    })
})

export interface CreateAccountStatementInput {
    clientId: string
    accountId: string
    fromDate: string
    toDate: string
    // format: "singlefile" | "multiplefiles"
}

export const createAccountStatement = async (input: CreateAccountStatementInput) => await instance.post("/command", {
    name: "wallet_create_client_account_statement",
    payloadJSON: JSON.stringify(input)
})

export interface Voucher {
    code: string
    discountPercentage: string
    description: string
    status: "active" | "frozen"
}

export interface CreateVoucherInput {
    clientId: string
    accountId: string
    code: string
    discountPercentage: number
}

export const createVoucher = async (input: CreateVoucherInput) => await instance.post("/command", {
    name: "wallet_create_voucher",
    payloadJSON: JSON.stringify(input)
})

export interface UpdateVoucherStatusInput {
    clientId: string
    accountId: string
    code: string
    status: "active" | "frozen"
}

export const updateVoucherStatus = async (input: UpdateVoucherStatusInput) => await instance.post("/command", {
    name: "wallet_update_voucher_status",
    payloadJSON: JSON.stringify(input)
})

export interface ListVouchersInput {
    clientId: string
    accountId: string
    status?: "active" | "frozen"
}

export const listVouchers = async (input: ListVouchersInput) => await instance.post("/query", {
    name: "wallet_list_vouchers",
    payloadJSON: JSON.stringify(input)
})

export interface CalculateVoucherValueInput {
    clientId: string
    accountId: string
    subscriptionAmount: number
    originalFeePercentage: number
    discountPercentage: number
}

export interface CalculateVoucherValueOutput {
    discountPercentage: number
    chargeableFeePercentage: number
    feeAmount: number
    subscriptionAmount: number
    originalFeePercentage: number
}

export const calculateVoucherValue = async (input: CalculateVoucherValueInput) => await instance.post("/query", {
    name: "wallet_calculate_voucher_value",
    payloadJSON: JSON.stringify(input)
})

export interface ListReferredClientsInput {
    clientId: string
    accountId: string
    referrerCode: string
}

export const listReferredClients = async (input: ListReferredClientsInput) => await instance.post("/query", {
    name: "wallet_list_referred_clients",
    payloadJSON: JSON.stringify(input)
})

export interface GetReferredClientsCountInput {
    clientId: string
    accountId: string
    referrerCode: string
}

export const getReferredClientsCount = async (input: GetReferredClientsCountInput) => await instance.post("/query", {
    name: "wallet_get_referred_clients_count",
    payloadJSON: JSON.stringify(input)
})

export interface Promo {
    label: string
    description: string
}

export interface ListPromos {
    clientId: string
    accountId: string
}

export const listPromos = async (input: ListPromos) => await instance.post("/query", {
    name: "wallet_list_promos",
    payloadJSON: JSON.stringify(input)
})

export interface GetSubscriptionFeeInput {
    clientId: string
    accountId: string
    fundId: string
    fundClassSequence: number
    amount: number
    voucherCode: null | string
}

export interface GetSubscriptionFeeOutput {
    fundSubscriptionFee: number
    clientSubscriptionFee: number
    voucherMrClientId: number
    voucherDiscountPercentage: number
    postFeeAmount: number
    voucherCodeUsed: string
}

export interface Error {
    code: string
    message: string
}

export const getSubscriptionFee = async (input: GetSubscriptionFeeInput) => await instance.post("/query", {
    name: "wallet_get_subscription_fee",
    payloadJSON: JSON.stringify(input)
})

export interface DuitNowBank {
    name: string
    code: string
    url: string
}

export interface ListDuitNowBanksInput {
    clientId: string
    accountId: string
}

export interface ListDuitNowBanksOutput {
    banks: DuitNowBank[]
}

export const listDuitNowBanks = async (input: ListDuitNowBanksInput) => await instance.post("/query", {
    name: "wallet_list_duitnow_banks",
    payloadJSON: JSON.stringify(input)
})

export interface InitiateDuitNowPaymentInput {
    clientId: string
    accountId: string
    requestId: string
    bankCode: string
}

export interface InitiateDuitNowPaymentOutput {
    payment: {
        url: string
        transactionId: string
        endToEndId: string
        endToEndIdSignature: string
    }
}

export const initiateDuitNowPayment = async (input: InitiateDuitNowPaymentInput) => await instance.post("/command", {
    name: "wallet_initiate_duitnow_payment",
    payloadJSON: JSON.stringify(input)
})

export interface GetRequestByDuitNowInput {
    clientId: string
    accountId: string
    endToEndId: string
}

export interface GetRequestByDuitNowOutput {
    request: {
        id: string
    }
}

export const getRequestByDuitNow = async (input: GetRequestByDuitNowInput) => await instance.post("/query", {
    name: "wallet_get_request_by_duitnow",
    payloadJSON: JSON.stringify(input)
})


export interface VerifyPolicyParticipantEmailInput {
    token: string
}

export interface VerifyPolicyParticipantEmailOutput {
    expired: boolean
}

export const verifyPolicyParticipantEmail = async (input: VerifyPolicyParticipantEmailInput) => await publicInstance.post("/command", {
    name: "public_verify_policy_participant_email",
    payloadJSON: JSON.stringify(input)
})


export interface ReviewClientRequestForSigningInput {
    token: string
}

export interface ReviewClientRequestForSigningOutput {
    client: {
        name: string
    }
    account: {
        id: string
        name: string
    }
    request: {
        fundName: string
        fundShortName: string
        fundClassLabel: string
        amount: number
        type: string
        salesChargePercentage: number
        postFeeAmount: number
        requestedBy: string
        createdAt: string
        status: string
    }
    expired: boolean
}

export const reviewClientRequestForSigning = async (input: ReviewClientRequestForSigningInput) => await publicInstance.post("/query", {
    name: "public_review_client_request_for_signing",
    payloadJSON: JSON.stringify(input)
})

export interface SignClientRequestInput {
    token: string
}

export interface SignClientRequestOutput {
    expired: boolean
}

export const signClientRequest = async (input: SignClientRequestInput) => await publicInstance.post("/command", {
    name: "public_sign_client_request",
    payloadJSON: JSON.stringify(input)
})

export interface GetClientAccountRequestPolicyInput {
    clientId: string
    accountId: string
    requestId: string
}

export interface GetClientAccountRequestPolicyOutput {
    groups : {
        label: string
        min: number
        max: number
    }[]
    participants: {
        name: string
        email: string
        groupLabel: string
        signed: boolean
        signedAt: string
    }[]
    lastNotifiedAt: string
}

export const getClientAccountRequestPolicy = async (input: GetClientAccountRequestPolicyInput) => await instance.post("/query", {
    name: "wallet_get_client_account_request_policy",
    payloadJSON: JSON.stringify(input)
})


export interface RequestClientAccountPolicySignatureInput {
    clientId: string
    accountId: string
    requestId: string
}

export interface RequestClientAccountPolicySignatureOutput {
}

export const requestClientAccountPolicySignature = async (input: RequestClientAccountPolicySignatureInput) => await instance.post("/command", {
    name: "wallet_request_client_account_policy_signature",
    payloadJSON: JSON.stringify(input)
})


export interface AllocationPerformanceBalance {
    date: string
    units: number
    value: {
        amount: number,
        asset: string
    }
    netAssetValuePerUnit: number
    postFeeAmount: {
        amount: number,
        asset: string
    }
}

export type Timeframe =  "1M" | "3M" | "6M" | "1Y" | "YTD" | "MAX"

export interface GetClientAccountAllocationPerformanceInput {
    clientId: string
    accountId: string
    fundId: string
    fundClassSequence: number
    interval: "day" | "week" | "month"
    timeframe: Timeframe
}

export interface GetClientAccountAllocationPerformanceOutput {
    balance: AllocationPerformanceBalance[]
}

export const getClientAccountAllocationPerformance = async (input: GetClientAccountAllocationPerformanceInput) => await instance.post("/query", {
    name: "wallet_get_client_account_allocation_performance",
    payloadJSON: JSON.stringify(input)
})

export interface ListClientAccountFundClassRequestsInput {
    clientId: string
    accountId: string
    fundId: string
    fundClassSequence: number
}

export interface ListClientAccountFundClassRequestsOutput {
    requests: AccountRequest[]
}

export const ListClientAccountFundClassRequests = async (input: ListClientAccountFundClassRequestsInput) => await instance.post("/query", {
    name: "wallet_list_client_account_fund_class_requests",
    payloadJSON: JSON.stringify(input)
})

export interface GetClientAccountRequestInput {
    clientId: string
    accountId: string
    requestId: string
}

export interface GetClientAccountRequestOutput {
    request: AccountRequest
}

export const GetClientAccountRequest = async (input: GetClientAccountRequestInput) => await instance.post("/query", {
    name: "wallet_get_client_account_request",
    payloadJSON: JSON.stringify(input)
})


export interface DepositBankAccount {
    name: string
    bankName: string
    number: string
    currency: string
    type: string
    bic: string
}

export interface DepositAddress {
    walletId: string
    asset: string
    chain: string
    assetType: "fiat" | "digital"
    assetLabel: string
    label: string
    address: string
    bankAccount: DepositBankAccount
}

export interface ListClientAccountDepositAddressesInput {
    clientId: string
    accountId: string
}

export interface ListClientAccountDepositAddressesOutput {
    addresses: DepositAddress[]
}

export const listClientAccountDepositAddresses = async (input: ListClientAccountDepositAddressesInput) => await instance.post("/query", {
    name: "wallet_list_client_account_deposit_addresses",
    payloadJSON: JSON.stringify(input)
})


export interface GetClientAccountDepositAssetInput {
    clientId: string
    accountId: string
    chain: string
    asset: string
}

export interface GetClientAccountDepositAssetOutput {
    asset: string
    assetLabel: string
    assetType: "fiat" | "digital"
    collection: {
        // set when assetType is "digital"
        address: string
        qrcodeBase64Image: string
        warningText: string
        // set when assetType is "fiat"
        bankAccount: {
            accountBank: string
            accountName: string
            accountNumber: string
            accountCurrency: string
            referenceNumber: string
            bic: string
            type: string
        }
    }
}

export const getClientAccountDepositAsset = async (input: GetClientAccountDepositAssetInput) => await instance.post("/query", {
    name: "wallet_get_client_account_deposit_asset",
    payloadJSON: JSON.stringify(input)
})

