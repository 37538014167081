import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { deleteAuthEmail, getSession, verifyAuthEmail, verifyAuthApplicant } from "../../api";

export default function CreateAccountVerify() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (!id) {
            navigate("/auth/email")
            return
        }
        if (searchParams.get("medium") === "mobile") {
            window.location.href = "halogen://wallet/create-account/verify/" + id
            return
        }
        if (searchParams.get("medium") === "mobile-test") {
            window.location.href = "halogentest://wallet/create-account/verify/" + id
            return
        }
        deleteAuthEmail().then(() => {
            getSession().then(resp => {
                verifyAuthApplicant(id).then(resp => {
                    if (resp.data.applicant) {
                        navigate(`/create-account/wizard`)
                        return
                    }
                    navigate("/")
                    return
                }).catch(() => {
                    navigate("/")
                    return
                })
            }).catch(() => {
                navigate("/")
                return
            })
        })
    }, [])

    return <></>
}
