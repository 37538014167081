import Tabs from "../../layout/tabs";
import { ArrowLeftOnRectangleIcon, BuildingLibraryIcon, DocumentTextIcon, LifebuoyIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { deleteAuthEmail } from "../../api";
import { useContext, useEffect } from "react";
import { PersonaContext } from "../login/context";
import { PortfolioContext } from "../portfolio/context";

export default function List() {
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)

    // TODO: have accountExperience in generic state
    const portfolio = useContext(PortfolioContext)

    useEffect(() => {
        if (!persona.authenticated) {
            navigate("/auth/email")
            return
        }
    },[])
    const handleLogout = () => {
        deleteAuthEmail().then(() => {
            persona.setActiveClientAccount("", "")
            navigate("/auth/email")
        }).catch()
    }
    return <div>
        <div className="h-32 pt-12 text-center hg-bg-blue" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <h1 className="text-xl text-white pl-3 pr-6">Settings</h1>
        </div>

        <ul className="mt-6">
            <li onClick={() => navigate("/settings/profile")} className="flex items-center border-b bg-white h-16 pt-3 text-sm font-medium cursor-pointer">
                <UserCircleIcon className="text-blue-900 w-10 h-10 stroke-1 ml-3"/>
                <span className="text-blue-950 font-light text-lg ml-5">Profile</span>
            </li>
            <li onClick={() => navigate("/settings/bank-account/list")} className="flex items-center border-b bg-white h-16 pt-3 text-sm font-medium cursor-pointer">
                <BuildingLibraryIcon className="text-blue-900 w-10 h-10 stroke-1 ml-3"/>
                <span className="text-blue-950 font-light text-lg ml-5">Bank accounts</span>
            </li>
            <li onClick={() => navigate("/settings/statement")} className="flex items-center border-b bg-white h-16 pt-3 text-sm font-medium cursor-pointer">
                <DocumentTextIcon className="text-blue-900 w-10 h-10 stroke-1 ml-3" />
                <span className="text-blue-950 font-light text-lg ml-5">Statements</span>
            </li>
            {/* <li onClick={() => navigate("/settings/delegate")} className="flex items-center border-b bg-white h-16 pt-3 text-sm font-medium cursor-pointer">
                <LockClosedIcon className="text-blue-900 w-10 h-10 stroke-1 ml-3" />
                <span className="text-blue-950 font-light text-lg ml-5">Delegates</span>
            </li> */}
            <li onClick={() => navigate("/settings/help")} className="flex items-center border-b bg-white h-16 pt-3 text-sm font-medium cursor-pointer">
                <LifebuoyIcon className="text-blue-900 w-10 h-10 stroke-1 ml-3" />
                <span className="text-blue-950 font-light text-lg ml-5">Help</span>
            </li>
            <li onClick={handleLogout} className="flex items-center border-b bg-white h-16 pt-3 text-sm font-medium cursor-pointer">
                <ArrowLeftOnRectangleIcon className="text-blue-900 w-10 h-10 stroke-1 ml-3" />
                <span className="text-blue-950 font-light text-lg ml-5">Logout</span>
            </li>
        </ul>

        <Tabs />
    </div>
}
